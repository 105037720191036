import { createContext, useReducer, useContext, Dispatch } from 'react'
import { mockUserInfo, IMockUserData } from 'libs/utils'

import userReducer, { IUserState, TUserActions, UserTypes } from './User-Reducer'
import { okta } from 'libs/codexdf/oktaAuthentication'


const emptyUserState : IUserState = {
    displayname: "",
    email: "",
    pfp: "",
    tenant: "",
    accessToken: "",
    admin: false,
    isAuthenticated: false,
    id: ""
}

export interface IUserContext {
    state: IUserState;
    dispatch: Dispatch<TUserActions>;
}

export const UserContext = createContext<IUserContext>({
    state: emptyUserState,
    dispatch: () => null
})

const mainReducer = (state: IUserState, action: TUserActions) => {
	const newState: IUserState = userReducer(state, action)

	return {
        displayname: newState.displayname,
        email: newState.email,
        pfp: newState.pfp,
        tenant: newState.tenant,
        accessToken: newState.accessToken,
        admin: newState.admin,
        isAuthenticated: newState.isAuthenticated,
        id: newState.id
	}
}

//TODO: Update how the wrapper grabs profile pictures 
const OktaAuthWrapper = (props: any) => {
    const { children } = props
    const { dispatch } = useContext(UserContext)

    const authChangedCallback = (user: any) => {
        const authenticated = user.isAuthenticated

        if (authenticated) {
            dispatchSignin(user)

        } else {
            dispatchSignout(user)

        }
    }
    okta.authStateManager.subscribe(authChangedCallback)

    const dispatchSignin = (user: any) => {

        // ideally dont sign-in the same account but harmless to do so
        // if (state.email === user.email) return
        const name = user.idToken.claims.name
        const email = user.idToken.claims.email
        const accessToken = user.accessToken.accessToken
        const uid = user.accessToken.claims.uid

        let userState: IUserState = {
            displayname: name,
            email: email,
            pfp: '',
            tenant: '',
            accessToken: accessToken,
            admin: false,
            isAuthenticated: true,
            id: uid
        }

        const info: IMockUserData | null = mockUserInfo(user.email)
        if (info) {
            userState.tenant = info.tenant
            userState.admin = info.admin
        }
        
        dispatch({
            type: UserTypes.Login,
            payload: {
                displayname: userState.displayname,
                email: userState.email,
                pfp: userState.pfp,
                tenant: userState.tenant,
                accessToken: userState.accessToken,
                admin: userState.admin,
                isAuthenticated: true,
                id: userState.id
            }
        })
    }

    const dispatchSignout = (user: any) => {
        // If already signed-out then dont signout again - actually harmless to do so
        // if (state.isAuthenticated === false && user == null) return
		dispatch({ type: UserTypes.Logout })
    }

    return children
}

export const UserContextProvider = (props: any) => {
    const [ state, dispatch ] = useReducer(mainReducer, emptyUserState)
    const { children } = props    
    return (
        <UserContext.Provider value={{ state, dispatch }}>
            <OktaAuthWrapper>
                { children }
            </OktaAuthWrapper>
        </UserContext.Provider>
    )
}

export default UserContextProvider
