export const timeStampStrToDate = (timestamp: string) => {
    const utcSeconds = +timestamp
    const d = new Date(0)
    d.setUTCSeconds(utcSeconds)
    return d.toDateString()
}

export const timeStampNumToDate = (utcSeconds: number) => {
    const d = new Date(0)
    d.setUTCSeconds(utcSeconds)
    return d.toDateString()
}
