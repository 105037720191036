import { getUidAndAccessToken } from 'libs/codexdf/oktaAuthentication'

export const logEvent = (event: string) => {
    let uid = getUidAndAccessToken()?.uid

    analytics.track("EVENT: " + event, {
        uid: uid ? uid?.toString() : ''
    });
}

export const logError = (error: string) => {
    let uid = getUidAndAccessToken()?.uid

    analytics.track("ERROR: " + error, {
        uid: uid ? uid?.toString() : ''
    });
}
