import CodexDFLogo from 'res/images/branding/cdf-logo.png'

const LogoImg = (props: any) => {
    const { className } = props

    return (
        <div className="flex justify-center">
            <img className={ "w-auto " + className } src={CodexDFLogo} alt="0xTracks Logo"/>
        </div>         
    )
}

export default LogoImg
