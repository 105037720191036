import { Suspense, lazy } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { UserContextProvider } from 'libs/context/user'
import { SyncContextProvider } from 'libs/context/sync'

import { ErrorBoundary } from 'react-error-boundary'

import AccentButton from 'libs/components/basic/AccentButton'
import { logError } from 'libs/logging'
//import AdminScreen from "./screens/AdminScreen";

import { okta } from "libs/codexdf/oktaAuthentication"
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth, Security } from '@okta/okta-react'
import LoginCallback from "./screens/LoginCallback"
import LogoImg from 'libs/components/branding/LogoImg'

const HeroScreen = lazy(() => import("./screens/HeroScreen"))
const AuthScreen = lazy(() => import("./screens/AuthScreen"))
const ForgotScreen = lazy(() => import("./screens/ForgotScreen"))
const HomeScreen = lazy(() => import("./screens/HomeScreen"))
const TransactionScreen = lazy(() => import('./screens/TransactionScreen'))
const TestScreen = lazy(() => import("./screens/TestScreen"))
const ReportsScreen = lazy(() => import("./screens/ReportsScreen"))
const HoldingsScreen = lazy(() => import("./screens/ReportsScreen/HoldingsScreen"))

// ############################################################################################################################################ //

const isProduction = process.env.NODE_ENV === 'production'
const productionUrl = process.env.REACT_APP_CODEX_MIDDLEWARE ? process.env.REACT_APP_CODEX_MIDDLEWARE : "https://codex-df.uc.r.appspot.com"
export const base_url = isProduction ? productionUrl : 'http://0.0.0.0:8081'

console.log('REACT_APP_CODEX_MIDDLEWARE is', process.env.REACT_APP_CODEX_MIDDLEWARE)
console.log('Environment is', process.env.NODE_ENV)
console.log('Middleware URL is', base_url);

// ############################################################################################################################################ //

const ErrorFallback = (props: any) => {
  logError(props.error)
  return (
    <div className="h-screen flex items-center justify-center">
      <AccentButton text="Click to Restart" onClick={props.resetErrorBoundary} />
    </div>
  )
}

const PrivateRoute = (props: any) => {
  const { element } = props
  const { authState } = useOktaAuth()
  const location = useLocation()

  if (!authState?.isAuthenticated && !authState?.isPending) {
    return <Navigate to="/login/callback" state={{ prevPath: location.pathname }} />
  }
  return element
}

// See the below link for documentation
// https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/App.tsx
const OktaAuthProvider = (props: any) => {
  let navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    if (originalUri) { // If originalUri exists, nav to it. Otherwise, do nothing.
      navigate(toRelativeUrl(originalUri, window.location.origin))
    }
  }

  return (
    <Security oktaAuth={okta} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </Security>
  )
}

const AppNavigation = () => {
  return (
    <>
      <Router>
        <OktaAuthProvider>
          <Suspense fallback={<div style={{ height: "10rem", width: "5rem", margin: "0 auto", padding: "10% 0" }}><LogoImg /></div>}>
            <Routes>
              <Route path="/">
                <Route index element={<HeroScreen />} />
                <Route path="auth" element={<AuthScreen />} />
                <Route path="forgot" element={<ForgotScreen />} />
                <Route path="dev" element={<PrivateRoute element={<TestScreen />} />} />
              </Route>

              <Route path="/app">
                <Route index element={<PrivateRoute element={<HomeScreen />} />} />
                <Route path="transactions" element={<PrivateRoute element={<TransactionScreen />} />} />
                <Route path="reports">
                  <Route index element={<PrivateRoute element={<ReportsScreen />} />} />
                  <Route path="holdings" element={<PrivateRoute element={<HoldingsScreen />} />} />
                </Route>
              </Route>

              <Route path='/login/callback' element={<LoginCallback />} />
              <Route path="*" element={<Navigate to="/app" />} />
            </Routes>
          </Suspense>
        </OktaAuthProvider>
      </Router>
    </>
  );
}

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <UserContextProvider>
        <SyncContextProvider>
          <AppNavigation />
        </SyncContextProvider>
      </UserContextProvider>
    </ErrorBoundary>
  );
}

export default App;
