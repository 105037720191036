import { ActionMapType } from 'libs/utils'

export interface IUserState {
    displayname: string
    email: string
    pfp: string
    tenant: string
    accessToken: string
    admin: boolean
    isAuthenticated: boolean,
    id: string
}

export enum UserTypes {
    Login = 'LOG_IN',
    Logout = 'LOG_OUT',
}
    
type TUserPayload = {
    [UserTypes.Login]: IUserState
    [UserTypes.Logout]: undefined
}

export type TUserActions = ActionMapType<TUserPayload>[keyof ActionMapType<TUserPayload>];
const userReducer = (state: IUserState, action: TUserActions) => {
    let updatedState : IUserState | null = null
    switch (action.type) {
        case UserTypes.Login:
            updatedState = {
                ...state,
                displayname: action.payload.displayname,
                email: action.payload.email,
                pfp: action.payload.pfp,
                tenant: action.payload.tenant,
                admin: action.payload.admin,
                accessToken: action.payload.accessToken,
                isAuthenticated: true,
                id:action.payload.id,
            };
            break;

        case UserTypes.Logout:
            updatedState = {
                displayname: "",
                email: "",
                pfp: "",
                tenant: "",
                accessToken: "",
                admin: false,
                isAuthenticated: false,
                id: "",
            }
            break;

        default:
            return state;
    }

    if (!updatedState)
        return state

    return updatedState
}

export default userReducer
