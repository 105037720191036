import { createContext, useReducer, Dispatch } from 'react'
import syncReducer, { ISyncState, TSyncActions } from './Sync-Reducer'

const emptySyncState : ISyncState = {
    syncing: false,
    lastSync: 0
}

export interface ISyncContext {
    state: ISyncState
    dispatch: Dispatch<TSyncActions>
}

export const SyncContext = createContext<ISyncContext>({
    state: emptySyncState,
    dispatch: () => null
})

const mainReducer = (state: ISyncState, action: TSyncActions) => {
	const newState: ISyncState = syncReducer(state, action)
	return {
        syncing: newState.syncing,
        lastSync: newState.lastSync,
	}
}

export const SyncContextProvider = (props: any) => {
    const [ state, dispatch ] = useReducer(mainReducer, emptySyncState)
    const { children } = props    

    return (
        <SyncContext.Provider value={{ state, dispatch }}>
            { children }
        </SyncContext.Provider>
    )
}

export default SyncContextProvider
