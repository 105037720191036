import { OktaAuth } from '@okta/okta-auth-js'

export const okta: OktaAuth = new OktaAuth({
	issuer: "https://dev-56649259.okta.com/oauth2/default",
	clientId: '0oa64xt4p412RuiG15d7',
	redirectUri: window.location.origin + "/login/callback",
	responseMode: 'fragment',
	tokenManager: { storage: 'sessionStorage' },
	useInteractionCodeFlow: true,
})
okta.start()

export const getUidAndAccessToken = () => {
    const authState = okta.authStateManager._authState

    if (!authState)
        return null

    const isAuthenticated = authState.isAuthenticated
    if (!isAuthenticated)
        return null

    const accessToken = authState.accessToken
    if (!accessToken)
        return null

    const uid = accessToken.claims.uid
    return {
        'uid': uid,
        'accessToken': accessToken.accessToken
    }
}

export const signInWithEmailAndPassword = async (username: string, password: string) => {
    let results = await okta.signIn({ username, password })
    console.log(results)

    if (results && results.user && results.user.profile) {
		return true;
	} else {
		return false;
	}
}

export const signOut = async () => {
	await okta.signOut()
}
