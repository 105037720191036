
// THIS IS A COPY OF https://github.com/okta/okta-react/blob/master/src/LoginCallback.tsx
// Pulled into codex directly because we want a bit more control - anselm aug 15 2022

import * as React from 'react'
import { Location, Navigate, useLocation } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'

const OktaError: React.FC<{ error: Error }> = ({ error }) => {
	if (error.name && error.message) {
		return <p>{error.name}: {error.message}</p>;
	}
	return <p>Error: {error.toString()}</p>;
}

export type OnAuthResumeFunction = () => void;

interface LoginCallbackProps {
	errorComponent?: React.ComponentType<{ error: Error }>;
	onAuthResume?: OnAuthResumeFunction;
	loadingElement?: React.ReactElement;
}

interface PrevPathLocation {
	prevPath: string
}


//TODO: Clean up this file
const LoginCallback: React.FC<LoginCallbackProps> = ({ errorComponent, loadingElement = null, onAuthResume = null }) => {
	const { oktaAuth, authState } = useOktaAuth();
	const [callbackError, setCallbackError] = React.useState(null);
	const location: Location = useLocation()

	const ErrorReporter = errorComponent || OktaError;

	// Handle the auth login - after screen is painted
	React.useEffect(() => {

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore OKTA-464505: backward compatibility support for auth-js@5
		const isInteractionRequired = oktaAuth.idx.isInteractionRequired || oktaAuth.isInteractionRequired.bind(oktaAuth)
		if (isInteractionRequired()) {
			// the okta version of this calls onAuthResume() to let the developer set history() which is obsolete and sadly navigate() cannot be used this way
			if (onAuthResume != null) {
				onAuthResume()
				return
			}
		}

		// Presumably perform the actual login... 
		oktaAuth.handleLoginRedirect().catch(e => {
			setCallbackError(e);
		});

	}, [oktaAuth, onAuthResume])

	// show error if any
	const authError = authState?.error;
	const displayError = callbackError || authError;
	if (displayError) {
		return <ErrorReporter error={displayError} />;
	}

	// rather than showing nothing - show something useful
	if (authState != null && authState.isAuthenticated) {
		const state = location.state as PrevPathLocation
		if (state && state.prevPath) {
			return <Navigate to={state.prevPath} />
		}

		return <Navigate to="/app" />
	} else {
		return <div>Updating...</div>
	}
};

export default LoginCallback;